import config from 'config';
import axios from 'axios';

const languageMap = {};

let enumsValuePro = null;
export function enumsValuesPromise() {
  if (enumsValuePro == null) {
    enumsValuePro = axios({
      method: 'get',
      baseURL: config.anon_path,
      url: 'meta/enums/values',
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      }
    })
      .then(res => res.data)
      .catch(err => {
        enumsValuePro = null;
        throw err;
      });
  }
  return enumsValuePro;
};

export function loadLang(culture) {
  if (languageMap[culture]) { return languageMap[culture]; }

  let loadPromise;
  switch (culture) {
    case 'en-AU':
      loadPromise = loadAll(import('./en'), import('./en-AU'));
      break;
    case 'en-NZ':
      loadPromise = loadAll(import('./en'), import('./en-NZ'));
      break;
    case 'en-US':
      loadPromise = loadAll(import('./en'), import('./en-US'));
      break;
    case 'en-CA':
      loadPromise = loadAll(import('./en'), import('./en-CA'));
      break;
    default:
      loadPromise = import('./en');
      break;
  }

  const enumsPromise = axios({
    method: 'get',
    baseURL: config.anon_path,
    url: `meta/enums?culture=${culture ? encodeURIComponent(culture) : ''}`,
    headers: {
      'X-Requested-With': 'XMLHttpRequest'
    }
  });

  languageMap[culture] = Promise.all([ loadPromise, enumsPromise, enumsValuesPromise ])
    .then(r => {
      const lang = r[0].default;
      const meta = { enums: r[1].data };
      lang.firstUpper = key => {
        if (!lang[key]) { throw new Error('Unknown lang key: ' + key); }
        return firstLetterUpper(lang[key]);
      };
      lang.toTitleCase = key => {
        if (!lang[key]) { throw new Error('Unknown lang key: ' + key); }
        return toTitleCase(lang[key]);
      };
      return { lang, meta };
    })
    .catch((err) => {
      languageMap[culture] = null;
      throw err;
    });

  return languageMap[culture];
}

function firstLetterUpper(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function toTitleCase(str) {
  return str.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1));
}

function loadAll(...imports) {
  return Promise.all(imports).then(res => {
    const results = res.map(r => r.default);
    return { default: Object.assign({}, ...results) };
  });
}